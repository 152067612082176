import { Stroke, Style } from "ol/style";
import { getLinearTagFlowStyle } from "./linearStyling";
import { interpolateYlOrRd } from "d3-scale-chromatic";
import { getDefaultColorStyle } from "./default";

export function getGradientStyleNormal(feature) {
  return getGradientColorStyle(feature);
}
export function getGradientStyleClick(feature) {
  return getGradientColorStyle(feature, true, false);
}
export function getGradientStyleHover(feature) {
  return getGradientColorStyle(feature, false, true);
}
function getGradientColorStyle(feature, click, hover) {
  let width, backgroundColor, backgroundWidth;
  let orbisGradient = feature.get("orbis-gradient");
  if (orbisGradient === undefined) {
    orbisGradient = feature.get("orbis-gradient:linear");
  }
  if (orbisGradient === undefined) {
    return getDefaultColorStyle(feature, click, hover);
  } else {
    if (hover) {
      backgroundColor = "black";
    } else if (click) {
      backgroundColor = "orange";
    } else {
      backgroundColor = "black";
    }
    if (hover || click) {
      width = 7;
      backgroundWidth = 10;
    } else {
      width = 5;
      backgroundWidth = 9;
    }
    const flowStyle = getLinearTagFlowStyle(
      orbisGradient,
      width,
      feature,
      interpolateYlOrRd,
    );
    return [
      flowStyle,
      new Style({
        stroke: new Stroke({
          color: backgroundColor,
          width: backgroundWidth,
        }),
      }),
    ];
  }
}

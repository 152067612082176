import { VectorFeatureControl } from "../controls/VectorFeatureControl";
import { getWFSLayer } from "../layers/WFSLayer";
import { getHoverLayer } from "../layers/HoverLayer";

export function addWFSLayerAndControl({
  layerName,
  url,
  role,
  style,
  azureLoginControl,
  featureInfoControl,
  div,
  map,
  getInteractions,
  featureInfoHandlers,
  hoverStyleFunctions,
}) {
  const control = new VectorFeatureControl({
    layer_name: layerName,
    target: div,
  });
  const layer = getWFSLayer(azureLoginControl, control, url, role, style);
  map.addControl(control);
  map.addLayer(layer);
  hoverStyleFunctions.forEach((hoverStyleFunction) => {
    const hoverLayer = getHoverLayer(hoverStyleFunction);
    map.addLayer(hoverLayer);
    featureInfoControl.addHoverLayerForLayer(hoverLayer, layer);
  });
  featureInfoHandlers.forEach((handler) =>
    featureInfoControl.addHandlerForLayer(handler, layer),
  );

  getInteractions(layer, control).forEach((interaction) =>
    map.addInteraction(interaction),
  );
  map.on("moveend", function () {
    control.set("zoom", map.getView().getZoom());
  });
  azureLoginControl.on("propertychange", function () {
    layer.getSource().refresh();
  });
}

import { Icon, Style } from "ol/style";
import Point from "ol/geom/Point";

export function defaultHoverStyles(feature) {
  const coordinates = feature.getGeometry().getCoordinates();
  const start = coordinates[coordinates.length - 2];
  const end = coordinates[coordinates.length - 1];
  const dx = end[0] - start[0];
  const dy = end[1] - start[1];
  const rotation = Math.atan2(dy, dx);
  const styles = [];
  styles.push(
    new Style({
      geometry: new Point(end),
      image: new Icon({
        src: "angle-right-icon.svg",
        anchor: [0.75, 0.5],
        rotateWithView: true,
        rotation: -rotation,
        color: "#063970",
        scale: [0.2, 0.2],
      }),
    }),
  );
  return styles;
}

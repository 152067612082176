import { fromLonLat, toLonLat } from "ol/proj";
import LineString from "ol/geom/LineString";
import { Fill, Stroke, Style } from "ol/style";
import Circle from "ol/geom/Circle";
import { CoordConverterGaussKruger } from "@orbis-lr-sdk/orbis-lr-sdk";

export function curvatureHoverStyles(feature) {
  //3857
  const coordinates = feature.getGeometry().getCoordinates();
  const curvature = feature.get("orbis-curvature:linear");
  const curvatureAbsolute = Math.abs(curvature);
  const end = coordinates[coordinates.length - 1];

  // Calculate the radius based on curvature
  const radius = 10000 / curvatureAbsolute;

  const circleCoordinates = [end[0], end[1]];

  const lonLat = toLonLat(circleCoordinates);
  const lsLonLat = coordinates.map((coord) => toLonLat(coord));
  const newLonLat = movePerpendicular(lonLat, lsLonLat, radius, curvature < 0);
  const midLonLat = [
    (lonLat[0] + newLonLat[0]) / 2,
    (lonLat[1] + newLonLat[1]) / 2,
  ];

  const startMercator = circleCoordinates;
  const midMercator = fromLonLat(midLonLat);

  const distanceAB = new LineString([startMercator, midMercator]).getLength();
  const styles = [];
  styles.push(
    new Style({
      geometry: new Circle(midMercator, distanceAB),
      fill: new Fill({ color: "rgba(0, 99, 112, 0.6)" }), // Semi-transparent fill
      stroke: new Stroke({ color: "#063970", width: 2 }), // Circle outline
    }),
  );
  return styles;
}

function movePerpendicular(lonLat, coordinates, distance, isNegative) {
  const [longitude, latitude] = lonLat;
  const converter = new CoordConverterGaussKruger(longitude);
  const [lonGK, latGK] = converter.convertWgsToGaussKrueger(
    longitude,
    latitude,
  );
  // Find the segment containing the point
  let closestPrev = null;
  let closestNext = null;

  for (let i = 0; i < coordinates.length - 1; i++) {
    const start = coordinates[i];
    const end = coordinates[i + 1];

    if (isPointOnSegment(start, end, lonLat)) {
      closestPrev = start;
      closestNext = end;
      break;
    }
  }

  if (!closestPrev || !closestNext) {
    throw new Error("Point is not on the linestring");
  }
  const closestPrevGK = converter.convertWgsToGaussKrueger(
    closestPrev[0],
    closestPrev[1],
  );
  const closestNextGK = converter.convertWgsToGaussKrueger(
    closestNext[0],
    closestNext[1],
  );

  // Calculate the angle of the line segment
  const dx = closestNextGK[0] - closestPrevGK[0];
  const dy = closestNextGK[1] - closestPrevGK[1];
  const angle = Math.atan2(dy, dx); // Angle in radians

  // Calculate the angle for perpendicular direction
  const perpendicularAngle = angle + Math.PI / 2; // 90 degrees in radians

  // Convert distance in meters to degrees
  const metersToDegrees = distance;

  // Calculate new coordinates
  let newLongitudeGK = lonGK + metersToDegrees * Math.cos(perpendicularAngle);
  let newLatitudeGK = latGK + metersToDegrees * Math.sin(perpendicularAngle);

  if (isNegative) {
    newLongitudeGK = lonGK - metersToDegrees * Math.cos(perpendicularAngle);
    newLatitudeGK = latGK - metersToDegrees * Math.sin(perpendicularAngle);
  }

  // Return new lonLat
  return converter.convertGaussKruegerToWgs(newLongitudeGK, newLatitudeGK);
}
function isPointOnSegment(start, end, point) {
  const d1 = distance(start, point);
  const d2 = distance(end, point);
  const segmentLength = distance(start, end);

  // Check if point is within a small tolerance of the segment
  const tolerance = 0.0001; // Adjust as needed
  return Math.abs(d1 + d2 - segmentLength) < tolerance;
}

function distance(coord1, coord2) {
  return Math.sqrt(
    Math.pow(coord2[0] - coord1[0], 2) + Math.pow(coord2[1] - coord1[1], 2),
  );
}

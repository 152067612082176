import Select from "ol/interaction/Select";
import { click, pointerMove } from "ol/events/condition";
import {
  getCurvatureStyleClick,
  getCurvatureStyleHover,
} from "../style/curvature";

export function getCurvatureInteractions(layer, control) {
  const clickSelect = new Select({
    layers: [layer],
    condition: click,
    style: function (feature) {
      return getCurvatureStyleClick(feature);
    },
  });
  clickSelect.on("select", function (e) {
    if (e.target.getFeatures().getLength() > 0) {
      control.set(
        "selectedFeatures",
        Array.from(e.target.getFeatures().getArray()),
      ); // create new array so that the event gets triggered
    } else {
      control.set("selectedFeatures", undefined);
    }
  });
  const hoverSelect = new Select({
    layers: [layer],
    condition: pointerMove,
    style: function (feature) {
      return getCurvatureStyleHover(feature);
    },
  });
  return [clickSelect, hoverSelect];
}

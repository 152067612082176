/**
 * @param {string|FeatureUrlFunction} url Feature URL service.
 * @param {boolean} xhrWithCredentials The value of withCredentials to set.
 * @param {Object<string,string|Function>} requestHeaders header, value pairs
 * @param {import("./format/Feature.js").default} format Feature format.
 * @param {import("./extent.js").Extent} extent Extent.
 * @param {number} resolution Resolution.
 * @param {import("./proj/Projection.js").default} projection Projection.
 * @param {function(Array<import("./Feature.js").default>, import("./proj/Projection.js").default): void} success Success
 *      Function called with the loaded features and optionally with the data projection.
 * @param {function(): void} failure Failure
 *      Function called when loading failed.
 */
export function loadFeaturesXhr(
  url,
  xhrWithCredentials,
  requestHeaders,
  format,
  extent,
  resolution,
  projection,
  success,
  failure,
) {
  const xhr = new XMLHttpRequest();
  xhr.open(
    "GET",
    typeof url === "function" ? url(extent, resolution, projection) : url,
    true,
  );
  for (const [header, value] of Object.entries(requestHeaders)) {
    if (value !== undefined) {
      xhr.setRequestHeader(
        header,
        typeof value === "function" ? value() : value,
      );
    }
  }
  if (format.getType() == "arraybuffer") {
    xhr.responseType = "arraybuffer";
  }
  xhr.withCredentials = xhrWithCredentials;
  /**
   * @param {Event} event Event.
   * @private
   */
  xhr.onload = function () {
    // status will be 0 for file:// urls
    if (!xhr.status || (xhr.status >= 200 && xhr.status < 300)) {
      const type = format.getType();
      /** @type {Document|Node|Object|string|undefined} */
      let source;
      if (type == "json" || type == "text") {
        source = xhr.responseText;
      } else if (type == "xml") {
        source = xhr.responseXML;
        if (!source) {
          source = new DOMParser().parseFromString(
            xhr.responseText,
            "application/xml",
          );
        }
      } else if (type == "arraybuffer") {
        source = /** @type {ArrayBuffer} */ (xhr.response);
      }
      if (source) {
        success(
          /** @type {Array<import("./Feature.js").default>} */
          (
            format.readFeatures(source, {
              extent: extent,
              featureProjection: projection,
            })
          ),
          format.readProjection(source),
        );
      } else {
        failure();
      }
    } else {
      failure();
    }
  };
  /**
   * @private
   */
  xhr.onerror = failure;
  xhr.send();
}
/**
 * Create an XHR feature loader for a `url` and `format`. The feature loader
 * loads features (with XHR), parses the features, and adds them to the
 * vector source.
 * @param {string|FeatureUrlFunction} url Feature URL service.
 * @param {import("./format/Feature.js").default} format Feature format.
 * @param {boolean} xhrWithCredentials The value of withCredentials to set.
 * @param {Object<string,string|Function>} requestHeaders header, value pairs
 * @return {FeatureLoader} The feature loader.
 * @api
 */
export function xhr(url, format, xhrWithCredentials, requestHeaders) {
  /**
   * @param {import("./extent.js").Extent} extent Extent.
   * @param {number} resolution Resolution.
   * @param {import("./proj/Projection.js").default} projection Projection.
   * @param {function(Array<import("./Feature.js").default>): void} [success] Success
   *      Function called when loading succeeded.
   * @param {function(): void} [failure] Failure
   *      Function called when loading failed.
   */
  return function (extent, resolution, projection, success, failure) {
    const source = /** @type {import("./source/Vector").default} */ (this);
    loadFeaturesXhr(
      url,
      xhrWithCredentials,
      requestHeaders,
      format,
      extent,
      resolution,
      projection,
      /**
       * @param {Array<import("./Feature.js").default>} features The loaded features.
       * @param {import("./proj/Projection.js").default} dataProjection Data
       * projection.
       */
      function (features) {
        source.addFeatures(features);
        if (success !== undefined) {
          success(features);
        }
      },
      /* FIXME handle error */ failure ? failure : undefined,
    );
  };
}

import { Stroke, Style } from "ol/style";
import { interpolatePuBu, interpolateRdPu } from "d3-scale-chromatic";
import { SUPPORTED_HIGHWAY_TYPES } from "./supportedHighwayTypes";
import { getLinearTagFlowStyle } from "./linearStyling";

export function getCurvatureStyleNormal(feature) {
  return getCurvatureColorStyle(feature);
}
export function getCurvatureStyleClick(feature) {
  return getCurvatureColorStyle(feature, true, false);
}
export function getCurvatureStyleHover(feature) {
  return getCurvatureColorStyle(feature, false, true);
}

function getCurvatureColorStyle(feature, click, hover) {
  let color, width, backgroundColor, backgroundWidth;

  let hdmapType = feature.get("hdmap_type");
  const osmHighway = feature.get("osm-highway");
  let colorInterpolator = interpolateRdPu;

  if (hdmapType !== undefined) {
    if (
      hdmapType !== "lane_center_line" &&
      hdmapType !== "lane_trajectory_line"
    ) {
      return;
    }
    colorInterpolator = interpolatePuBu;
  }

  let type = feature.get("type");
  if (type === "ORBIS_RELATION") {
    return;
  }

  let orbisCurvature = feature.get("orbis-curvature");
  if (orbisCurvature === undefined) {
    orbisCurvature = feature.get("orbis-curvature:linear");
  }
  if (orbisCurvature === undefined) {
    if (osmHighway === undefined) {
      color = "black";
    } else if (!SUPPORTED_HIGHWAY_TYPES.includes(osmHighway)) {
      color = "black";
    } else {
      color = "grey";
    }

    backgroundColor = "white";
    width = 2;
    backgroundWidth = 1;
    if (hover || click) {
      return [
        new Style({
          stroke: new Stroke({
            color: color,
            width: width,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: backgroundColor,
            width: backgroundWidth,
          }),
        }),
      ];
    }
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
    ];
  } else {
    if (hover) {
      backgroundColor = "black";
    } else if (click) {
      backgroundColor = "orange";
    } else {
      backgroundColor = "black";
    }
    if (hover || click) {
      width = 7;
      backgroundWidth = 10;
    } else {
      width = 5;
      backgroundWidth = 9;
    }

    const flowStyle = getLinearTagFlowStyle(
      orbisCurvature,
      width,
      feature,
      colorInterpolator,
    );
    return [
      flowStyle,
      new Style({
        stroke: new Stroke({
          color: backgroundColor,
          width: backgroundWidth,
        }),
      }),
    ];
  }
}

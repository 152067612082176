import { Style, Stroke } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { isSD } from "./style/crosslink";

export function addCrosslinkHoverLayer(map) {
  const vectorSource = new VectorSource({});

  const styleFunction = function (feature) {
    let LINE_COLOR;
    let FILL_COLOR;
    if (isSD(feature)) {
      LINE_COLOR = "red";
      FILL_COLOR = "white";
    } else {
      LINE_COLOR = "blue";
      FILL_COLOR = "white";
    }
    const styles = [
      // linestring
      new Style({
        stroke: new Stroke({
          color: LINE_COLOR,
          width: 7,
        }),
        zIndex: 12,
      }),
      new Style({
        stroke: new Stroke({
          color: FILL_COLOR,
          width: 5,
        }),
        zIndex: 13,
      }),
    ];
    return styles;
  };

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });

  vectorLayer.setZIndex(100);
  map.addLayer(vectorLayer);
  map.on("click", function () {
    vectorSource.clear();
  });
  return vectorLayer;
}

import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

export function getHoverLayer(styleFunction) {
  const vectorSource = new VectorSource({});
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });
  vectorLayer.setZIndex(100);

  return vectorLayer;
}

import FlowLine from "ol-ext/style/FlowLine";
import { scaleLinear, scaleLog } from "d3-scale";
import { lengthOnPlaneCm, LRDecoder } from "@orbis-lr-sdk/orbis-lr-sdk";
import { InterpolationSpecType } from "@orbis-lr-sdk/orbis-lr-sdk";
import { featureTo4326Geometry } from "../../util";

export function lrDecode(key, lrTag) {
  const decoder = new LRDecoder().withInterpolationSpecType(
    InterpolationSpecType.TAG,
  );
  const lrChain = decoder.decode(key, lrTag);
  return lrChain;
}

function getLengthCm(feature) {
  const geometry = featureTo4326Geometry(feature);
  const lengthCm = lengthOnPlaneCm(geometry);
  return lengthCm;
}

export function getLinearTagFlowStyle(
  linearTag,
  width,
  feature,
  colorInterpolator,
) {
  const lengthCm = getLengthCm(feature);
  const lrChain = lrDecode("bla:linear", linearTag);

  const offsets = lrChain.elements.map(
    (element) => element.getStartDistance() / lengthCm,
  );
  const valuesInLinearTag = lrChain.elements.map((element) =>
    parseInt(element.getValue()),
  );
  const scaleLinearFunction = scaleLinear(offsets, valuesInLinearTag);
  const offsetToLinearTag = function wrapper(offset) {
    if (lengthCm === 0) {
      return 0;
    } else {
      return scaleLinearFunction(offset);
    }
  };

  const normalizeLinearTagPositive = scaleLog([1, 350], [0, 1]);

  const normalizeLinearTag = function (linearTag) {
    if (linearTag < 1 && linearTag > -1) {
      return 0;
    }
    if (linearTag > 0) {
      return normalizeLinearTagPositive(linearTag);
    }
    return normalizeLinearTagPositive(-linearTag);
  };

  const flowStyle = new FlowLine({
    color: function (f, step) {
      const linearTag = offsetToLinearTag(step);

      if (isNaN(linearTag)) {
        return "rgb(128, 128, 128)";
      }

      const linearTagNormalized = normalizeLinearTag(linearTag);
      const color = colorInterpolator(linearTagNormalized);
      if (color === "rgb(0, 0, 0)") {
        return "rgb(255, 255, 255)";
      }
      return color;
    },
    width: width,
    visible: false,
  });

  return flowStyle;
}

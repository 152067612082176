import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { get as getProjection, transformExtent } from "ol/proj";
import { xhr } from "../featureLoaderWithHeaders";
import { lengthOnPlaneCm } from "@orbis-lr-sdk/orbis-lr-sdk";
import { featureTo4326Geometry } from "../util";

// API parameters

/**
 * @param {AzureLoginControl} azureLoginControl
 * @param {WFSControl} vectorFeatureControl
 * @param {url} url
 * @param {function} getStyleNormal
 * @returns
 */
export function getWFSLayer(
  azureLoginControl,
  vectorFeatureControl,
  url,
  role,
  getStyleNormal,
) {
  const vectorSource = new VectorSource({
    loader: xhr(
      function (extent, resolution, projection) {
        const extentTransformed = transformExtent(
          extent,
          projection,
          "EPSG:4326",
        );
        return (
          url +
          "?" +
          "SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=Way&" +
          "SRSNAME=urn:ogc:def:crs:EPSG::4326&" +
          "OUTPUTFORMAT=json&" +
          "BBOX=" +
          extentTransformed[1] +
          "," +
          extentTransformed[0] +
          "," +
          extentTransformed[3] +
          "," +
          extentTransformed[2] +
          ",urn:ogc:def:crs:EPSG::4326"
        );
      },
      new GeoJSON(),
      true,
      {
        Authorization: function () {
          return "Bearer " + azureLoginControl.get("idToken");
        },
      },
    ),
    strategy: bboxStrategy,
    projection: getProjection("EPSG:4326"),
  });
  vectorSource.on("addfeature", (event) => {
    if (event.feature.getGeometry().getType() === "LineString") {
      const geometry = featureTo4326Geometry(event.feature);
      event.feature.set("lengthCm", lengthOnPlaneCm(geometry));
    }
    renameToLRStep(event, "orbis-oneway");
    renameToLRStep(event, "osm-oneway");
    renameToLRStep(event, "orbis-dual_carriageway");
    renameToLRStep(event, "osm-navigability");
    renameToLRStep(event, "orbis-navigability");
    renameToLRStep(event, "lane_border_type");
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    minZoom: 15,
    zIndex: 11,
    style: function (feature) {
      return getStyleNormal(feature);
    },
  });

  vectorFeatureControl.on("propertychange", function (e) {
    if (e.key !== "loading" && e.key !== "tooLarge") {
      propertyChangeHandler(
        vectorFeatureControl,
        azureLoginControl,
        vectorLayer,
      );
    }
    if (e.key === "zoom") {
      const zoom = e.target.get("zoom");
      if (
        zoom >= vectorLayer.getMinZoom() &&
        zoom <= vectorLayer.getMaxZoom()
      ) {
        vectorFeatureControl.set("active", true);
      } else {
        vectorFeatureControl.set("active", false);
      }
    }
  });

  azureLoginControl.on("propertychange", function () {
    if (
      azureLoginControl.get("idToken") &&
      azureLoginControl.get("idTokenClaims") &&
      azureLoginControl.get("idTokenClaims").roles &&
      azureLoginControl.get("idTokenClaims").roles.includes(role)
    ) {
      vectorFeatureControl.set("enabled", true);
    } else {
      vectorFeatureControl.set("enabled", false);
    }
  });
  return vectorLayer;
}

/**
 * @param {WFSControl} vectorFeatureControl
 * @param {AzureLoginControl} azureLoginControl
 * @param {VectorLayer} vectorLayer
 */
function propertyChangeHandler(
  vectorFeatureControl,
  azureLoginControl,
  vectorLayer,
) {
  if (!azureLoginControl.get("idToken")) {
    vectorLayer.setVisible(false);
    vectorFeatureControl.set("idTokenSet", false);
  } else {
    vectorFeatureControl.set("idTokenSet", true);
    if (vectorFeatureControl.get("vectorToggle") == true) {
      vectorLayer.setVisible(true);
    } else {
      // When vectorToggle is not set, the checkbox is not checked, and so the layer is made invisible.
      vectorLayer.setVisible(false);
    }
  }
}

function renameToLRStep(event, tagKey) {
  renameToLR(event, tagKey, "step");
}
function renameToLR(event, tagKey, lrType) {
  if (
    !tagKey.endsWith(":" + lrType) &&
    event.feature.get(tagKey) !== undefined &&
    event.feature.get(tagKey).includes("#")
  ) {
    event.feature.set(tagKey + ":" + lrType, event.feature.get(tagKey));
    event.feature.unset(tagKey);
  }
}

import { getNavigabilityInteractions } from "../layers/interactions/navigability";
import { getGradientInteractions } from "../layers/interactions/gradient";
import { getDualCarriagewayInteractions } from "../layers/interactions/dualCarriageway";
import { getOnewayInteractions } from "../layers/interactions/oneway";
import { getDualCarriagewayStyleNormal } from "../layers/style/dualCarriageway";
import { getOnewayStyleNormal } from "../layers/style/oneway";
import { getOsmOnewayStyleNormal } from "../layers/style/osmOneway";
import { getGradientStyleNormal } from "../layers/style/gradient";
import { getElevationStyleNormal } from "../layers/style/elevation";
import { getNavigabilityStyleNormal } from "../layers/style/navigability";
import { getEmissionRegulationStyleNormal } from "../layers/style/lez";
import { getEmissionRegulationInteractions } from "../layers/interactions/lez";
import { getCurvatureStyleNormal } from "../layers/style/curvature";
import { getCurvatureInteractions } from "../layers/interactions/curvature";
import { getCrosslinkStyleNormal } from "../layers/style/crosslink";
import { getCrosslinkInteractions } from "../layers/interactions/crosslink";
import { getHDMapStyleNormal } from "../layers/style/hdmap";
import { getHDMapInteractions } from "../layers/interactions/hdmap";
import { getHDLaneBorderStyleNormal } from "../layers/style/hdlaneborder";
import { getInteractions as getOnewayInternalInteractions } from "../layers/interactions/onewayInternal";
import { getStyleNormal as getOnewayInternalStyleNormal } from "../layers/style/onewayInternal";
import { getInteractions as getTTNavInternalInteractions } from "../layers/interactions/ttnavInternal";
import { getStyleNormal as getTTNavStyleNormal } from "../layers/style/ttnavInternal";
import { getModerationInteractions } from "../layers/interactions/moderation";
import { getModerationStyleNormal } from "../layers/style/moderation";
import { addWFSLayerAndControl } from "./WFSLayersAndControls";
import {
  addFGBLayerAndControl,
  concatenateFeatureFields,
  customizeFeatureId,
  enableIfAccessible,
  getPreviousMonthsUrls,
} from "./FGBLayersAndControls";
import { getOnewayInternalMonths } from "../parameters/parameters";
import { getElevationInteractions } from "../layers/interactions/elevation";
import { addCrosslinkHoverLayer } from "../layers/CrosslinkHoverLayer";
import { getCrosslinkLayerHander } from "../layers/featureinfohandlers/CrosslinkLayerHandler";
import { defaultHoverStyles } from "../layers/style/hover/defaultHoverStyle";
import { curvatureHoverStyles } from "../layers/style/hover/curvatureHoverStyle";
import { getHDLaneBorderInteractions } from "../layers/interactions/hdlaneborder";

export function addLayersAndControls(
  map,
  azureLoginControl,
  featureInfoControl,
  target,
) {
  // one way

  //curvature
  const curvatureCollapse = getCollapsible("Curvature");
  const onewayCollapse = getCollapsible("Oneway");
  const dcwCollapse = getCollapsible("Dualcarriageway");
  const navCollapse = getCollapsible("Navigability");
  const gradientCollapse = getCollapsible("Gradient");
  const hdCollapse = getCollapsible("HD");
  const lezCollapse = getCollapsible("LEZ");
  const hdlanerestriction = getCollapsible("HD lane restriction");

  target.appendChild(onewayCollapse);
  target.appendChild(navCollapse);
  target.appendChild(dcwCollapse);
  target.appendChild(lezCollapse);

  target.appendChild(gradientCollapse);
  target.appendChild(curvatureCollapse);

  target.appendChild(hdCollapse);
  target.appendChild(hdlanerestriction);

  addWFSLayerAndControl({
    layerName: "One way dev 28798",
    url: process.env.ORBIS_WFS_ONEWAY_DEV_28798_URL,
    role: process.env.ORBIS_WFS_ONEWAY_DEV_ROLE,
    style: getOnewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: onewayCollapse,
    map: map,
    getInteractions: getOnewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });
  addWFSLayerAndControl({
    layerName: "One way prod 21591",
    url: process.env.ORBIS_WFS_ONEWAY_PROD_21591_URL,
    role: process.env.ORBIS_WFS_ONEWAY_PROD_ROLE,
    style: getOnewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: onewayCollapse,
    map: map,
    getInteractions: getOnewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // osm one way
  addWFSLayerAndControl({
    layerName: "OSM one way dev 17772",
    url: process.env.ORBIS_WFS_OSM_ONEWAY_DEV_17772_URL,
    role: process.env.ORBIS_WFS_OSM_ONEWAY_DEV_ROLE,
    style: getOsmOnewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: onewayCollapse,
    map: map,
    getInteractions: getOnewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });
  addWFSLayerAndControl({
    layerName: "OSM one way prod 26874",
    url: process.env.ORBIS_WFS_OSM_ONEWAY_PROD_26874_URL,
    role: process.env.ORBIS_WFS_OSM_ONEWAY_PROD_ROLE,
    style: getOsmOnewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: onewayCollapse,
    map: map,
    getInteractions: getOnewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // dual carriageway
  addWFSLayerAndControl({
    layerName: "Dual carriageway dev 22398",
    url: process.env.ORBIS_WFS_DCW_DEV_22398_URL,
    role: process.env.ORBIS_WFS_DCW_DEV_ROLE,
    style: getDualCarriagewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: dcwCollapse,
    map: map,
    getInteractions: getDualCarriagewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });
  addWFSLayerAndControl({
    layerName: "Dual carriageway prod 28200",
    url: process.env.ORBIS_WFS_DCW_PROD_28200_URL,
    role: process.env.ORBIS_WFS_DCW_PROD_ROLE,
    style: getDualCarriagewayStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: dcwCollapse,
    map: map,
    getInteractions: getDualCarriagewayInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // tt-navigabilility
  addWFSLayerAndControl({
    layerName: "TT-Navigability dev 19150",
    url: process.env.ORBIS_WFS_NAVIGABILITY_DEV_19150_URL,
    role: process.env.ORBIS_WFS_NAVIGABILITY_DEV_ROLE,
    style: getNavigabilityStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: navCollapse,
    map: map,
    getInteractions: getNavigabilityInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });
  addWFSLayerAndControl({
    layerName: "TT-Navigability prod 20063",
    url: process.env.ORBIS_WFS_NAVIGABILITY_PROD_20063_URL,
    role: process.env.ORBIS_WFS_NAVIGABILITY_PROD_ROLE,
    style: getNavigabilityStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: navCollapse,
    map: map,
    getInteractions: getNavigabilityInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // osm-navigability
  addWFSLayerAndControl({
    layerName: "OSM-Navigability dev 27657",
    url: process.env.ORBIS_WFS_OSMNAV_DEV_27657_URL,
    role: process.env.ORBIS_WFS_NAVIGABILITY_DEV_ROLE,
    style: getNavigabilityStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: navCollapse,
    map: map,
    getInteractions: getNavigabilityInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });
  addWFSLayerAndControl({
    layerName: "OSM-Navigability prod 11442",
    url: process.env.ORBIS_WFS_OSMNAV_PROD_11442_URL,
    role: process.env.ORBIS_WFS_NAVIGABILITY_PROD_ROLE,
    style: getNavigabilityStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: navCollapse,
    map: map,
    getInteractions: getNavigabilityInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // gradient
  addWFSLayerAndControl({
    layerName: "Gradient dev 82859",
    url: process.env.ORBIS_WFS_GRADIENT_DEV_82859_URL,
    role: process.env.ORBIS_WFS_GRADIENT_HD_DEV_ROLE,
    style: getGradientStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: gradientCollapse,
    map: map,
    getInteractions: getGradientInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Gradient dev 13999",
    url: process.env.ORBIS_WFS_GRADIENT_DEV_13999_URL,
    role: process.env.ORBIS_WFS_GRADIENT_DEV_ROLE,
    style: getGradientStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: gradientCollapse,
    map: map,
    getInteractions: getGradientInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Gradient prod 11880",
    url: process.env.ORBIS_WFS_GRADIENT_PROD_11880_URL,
    role: process.env.ORBIS_WFS_GRADIENT_PROD_ROLE,
    style: getGradientStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: gradientCollapse,
    map: map,
    getInteractions: getGradientInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addFGBLayerAndControl({
    layerName: "Genesis SD gradient",
    target: gradientCollapse,
    map: map,
    getInteractions: getGradientInteractions,
    sortField: "inserted_at",
    urls: [process.env.GENESIS_GRADIENT_FLATGEOBUF_URL],
    style: getGradientStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addFGBLayerAndControl({
    layerName: "Gradient SD DEM",
    target: gradientCollapse,
    map: map,
    getInteractions: getGradientInteractions,
    sortField: "inserted_at",
    urls: [process.env.DEM_GRADIENT_FLATGEOBUF_URL],
    style: getGradientStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  // elevation
  addWFSLayerAndControl({
    layerName: "Elevation dev 67941",
    url: process.env.ORBIS_WFS_ELEVATION_DEV_67941_URL,
    role: process.env.ORBIS_WFS_ELEVATION_DEV_ROLE,
    style: getElevationStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: gradientCollapse,
    map: map,
    getInteractions: getElevationInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addFGBLayerAndControl({
    layerName: "Elevation SD DEM",
    target: gradientCollapse,
    map: map,
    getInteractions: getElevationInteractions,
    sortField: "inserted_at",
    urls: [process.env.DEM_ELEVATION_FLATGEOBUF_URL],
    style: getElevationStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  //curvature
  addWFSLayerAndControl({
    layerName: "Orbis Curvature SD prod 15751",
    url: process.env.ORBIS_WFS_CURVATURE_PROD_15751_URL,
    role: process.env.ORBIS_WFS_CURVATURE_PROD_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Orbis Curvature HD prod 95495",
    url: process.env.ORBIS_WFS_CURVATURE_PROD_95495_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Orbis Curvature SD dev 15916",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_15916_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Orbis Curvature SD dev 76519 (incl. HAPT)",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_76519_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "OSM Curvature SD dev 15702",
    url: process.env.ORBIS_WFS_OSM_CURVATURE_DEV_15702_URL,
    role: process.env.ORBIS_WFS_OSM_CURVATURE_DEV_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  //curvature HD
  addWFSLayerAndControl({
    layerName: "Orbis Curvature HD dev 67542",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_67542_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Orbis Curvature HD dev 50761 (incl. HAPT)",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_50761_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_EXT_ROLE,
    style: getCurvatureStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles, curvatureHoverStyles],
  });

  addFGBLayerAndControl({
    layerName: "Genesis SD curvatures",
    target: curvatureCollapse,
    map: map,
    getInteractions: getCurvatureInteractions,
    sortField: "inserted_at",
    urls: [process.env.GENESIS_CURVATURES_FLATGEOBUF_URL],
    style: getCurvatureStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addWFSLayerAndControl({
    layerName: "Crosslink 27572 (incl. HAPT)",
    url: process.env.ORBIS_WFS_CROSSLINK_27572_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getCrosslinkStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdCollapse,
    map: map,
    getInteractions: getCrosslinkInteractions,
    featureInfoHandlers: [
      getCrosslinkLayerHander(addCrosslinkHoverLayer(map).getSource()),
    ],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Crosslink 10102",
    url: process.env.ORBIS_WFS_CROSSLINK_10102_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getCrosslinkStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdCollapse,
    map: map,
    getInteractions: getCrosslinkInteractions,
    featureInfoHandlers: [
      getCrosslinkLayerHander(addCrosslinkHoverLayer(map).getSource()),
    ],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "HD lane restrictions 91567",
    url: process.env.ORBIS_WFS_HDLANERESTRICTION_91567_URL,
    role: process.env.ORBIS_WFS_HDLANERESTRICTION_DEV_ROLE,
    style: getGradientStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdlanerestriction,
    map: map,
    getInteractions: getGradientInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "Roadmarkings 11529",
    url: process.env.ORBIS_WFS_ROADMARKINGS_11529_URL,
    role: process.env.ORBIS_WFS_HDLANERESTRICTION_DEV_ROLE,
    style: getGradientStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdlanerestriction,
    map: map,
    getInteractions: getGradientInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "HD Basemap 13436",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_67542_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getHDMapStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdCollapse,
    map: map,
    getInteractions: getHDMapInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "HD Lane borders 13436",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_67542_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_ROLE,
    style: getHDLaneBorderStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdCollapse,
    map: map,
    getInteractions: getHDLaneBorderInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "HD Basemap 29351 (incl HAPT)",
    url: process.env.ORBIS_WFS_CURVATURE_DEV_50761_URL,
    role: process.env.ORBIS_WFS_CURVATURE_DEV_HD2_0_EXT_ROLE,
    style: getHDMapStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: hdCollapse,
    map: map,
    getInteractions: getHDMapInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  // lez
  addWFSLayerAndControl({
    layerName: "LEZ dev 21372",
    url: process.env.ORBIS_WFS_LEZ_DEV_21372_URL,
    role: process.env.ORBIS_WFS_LEZ_DEV_ROLE,
    style: getEmissionRegulationStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: lezCollapse,
    map: map,
    getInteractions: getEmissionRegulationInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addWFSLayerAndControl({
    layerName: "LEZ prod 22666",
    url: process.env.ORBIS_WFS_LEZ_PROD_22666_URL,
    role: process.env.ORBIS_WFS_LEZ_PROD_ROLE,
    style: getEmissionRegulationStyleNormal,
    azureLoginControl: azureLoginControl,
    featureInfoControl: featureInfoControl,
    div: lezCollapse,
    map: map,
    getInteractions: getEmissionRegulationInteractions,
    featureInfoHandlers: [],
    hoverStyleFunctions: [defaultHoverStyles],
  });

  addFGBLayerAndControl({
    layerName: "Orbis one way internals dev",
    target: onewayCollapse,
    map: map,
    getInteractions: getOnewayInternalInteractions,
    sortField: "inserted_at",
    urls: getPreviousMonthsUrls(
      process.env.ONEWAY_STATEMENTS_FLATGEOBUF_URL_DEV_PREFIX,
      getOnewayInternalMonths(),
    ),
    style: getOnewayInternalStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addFGBLayerAndControl({
    layerName: "Orbis one way internals prod",
    target: onewayCollapse,
    map: map,
    getInteractions: getOnewayInternalInteractions,
    sortField: "inserted_at",
    urls: getPreviousMonthsUrls(
      process.env.ONEWAY_STATEMENTS_FLATGEOBUF_URL_PROD_PREFIX,
      getOnewayInternalMonths(),
    ),
    style: getOnewayInternalStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addFGBLayerAndControl({
    layerName: "TT-Navigability internals dev",
    target: navCollapse,
    map: map,
    getInteractions: getTTNavInternalInteractions,
    sortField: "inserted_at",
    urls: getPreviousMonthsUrls(
      process.env.TTNAV_STATEMENTS_FLATGEOBUF_URL_DEV_PREFIX,
      5,
    ),
    style: getTTNavStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addFGBLayerAndControl({
    layerName: "TT-Navigability internals prod",
    target: navCollapse,
    map: map,
    getInteractions: getTTNavInternalInteractions,
    sortField: "inserted_at",
    urls: getPreviousMonthsUrls(
      process.env.TTNAV_STATEMENTS_FLATGEOBUF_URL_PROD_PREFIX,
      5,
    ),
    style: getTTNavStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["way_id", "inserted_at"]),
    ),
  });

  addFGBLayerAndControl({
    layerName: "Orbis one way moderations",
    target: onewayCollapse,
    map: map,
    getInteractions: getModerationInteractions,
    sortField: "last_moderated_at",
    urls: [process.env.ONEWAY_MODERATIONS_FLATGEOBUF_URL],
    style: getModerationStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["id", "last_moderated_at"]),
    ),
    minZoom: 10,
    settingConfigurationFn: enableIfAccessible,
  });

  addFGBLayerAndControl({
    layerName: "TT Navigability moderations",
    target: navCollapse,
    map: map,
    getInteractions: getModerationInteractions,
    sortField: "last_moderated_at",
    urls: [process.env.TTNAV_MODERATIONS_FLATGEOBUF_URL],
    style: getModerationStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["id", "last_moderated_at"]),
    ),
    minZoom: 10,
    settingConfigurationFn: enableIfAccessible,
  });

  addFGBLayerAndControl({
    layerName: "OSM Navigability moderations",
    target: navCollapse,
    map: map,
    getInteractions: getModerationInteractions,
    sortField: "last_moderated_at",
    urls: [process.env.OSMNAV_MODERATIONS_FLATGEOBUF_URL],
    style: getModerationStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["id", "last_moderated_at"]),
    ),
    minZoom: 10,
    settingConfigurationFn: enableIfAccessible,
  });

  addFGBLayerAndControl({
    layerName: "Dual carriageway moderations",
    target: dcwCollapse,
    map: map,
    getInteractions: getModerationInteractions,
    sortField: "inserted_at",
    urls: [process.env.DCW_MODERATIONS_FLATGEOBUF_URL],
    style: getModerationStyleNormal,
    transformFeature: customizeFeatureId(
      concatenateFeatureFields(["id", "inserted_at"]),
    ),
    minZoom: 10,
    settingConfigurationFn: enableIfAccessible,
  });
}

function getCollapsible(name) {
  const details = document.createElement("details");
  const summary = document.createElement("summary");
  summary.append(name);
  details.appendChild(summary);
  return details;
}

import { lineString, point } from "@turf/helpers";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { ValueAtProducer } from "@orbis-lr-sdk/orbis-lr-sdk";
import { distanceAlongCm, toLatLon } from "../../util";

export function getCrosslinkLayerHander(vizVectorSource) {
  return (feature, layer, position) => {
    const latLonGeometry = toLatLon(
      lineString(feature.getGeometry().getCoordinates()).geometry,
    );
    const latLonEventPoint = toLatLon(point(position).geometry);
    const closestPointLatLon = nearestPointOnLine(
      latLonGeometry,
      latLonEventPoint,
    );
    const distanceAtFeatureCm = distanceAlongCm(
      latLonGeometry,
      closestPointLatLon,
    );
    const featureId = feature.getId();
    const relationsOfFeature = layer
      .getSource()
      .getFeatures()
      .filter((feature) => {
        const memberIdKeys = feature
          .getKeys()
          .filter(
            (key) => key.startsWith("relation-member-") && key.endsWith("-id"),
          );
        if (memberIdKeys.length === 0) {
          return false;
        }
        return (
          memberIdKeys
            .filter((memberIdKey) => feature.get(memberIdKey) === featureId)
            .map((memberIdKey) => memberIdKey.replace("-id", "-role"))
            .map((memberIdRole) => feature.get(memberIdRole))
            .map((role) => role.split("=", 2))
            .map((tag) =>
              ValueAtProducer.getValueAt(tag[0], tag[1], distanceAtFeatureCm),
            )
            .filter((value) => value !== undefined).length > 0
        );
      });
    if (relationsOfFeature.length > 0) {
      vizVectorSource.clear();
      relationsOfFeature.forEach((relation) => {
        const memberIdKeys = relation
          .getKeys()
          .filter(
            (key) => key.startsWith("relation-member-") && key.endsWith("-id"),
          );
        if (memberIdKeys.length === 0) {
          return false;
        }
        const otherMemberIdKeys = memberIdKeys.filter(
          (memberIdKey) => relation.get(memberIdKey) !== featureId,
        );
        const otherMemberId = otherMemberIdKeys.map((memberIdKey) =>
          relation.get(memberIdKey),
        )[0];
        // const otherMemberIdRole = otherMemberIdKeys.map((memberIdKey) =>
        //   memberIdKey.replace("-id", "-role"),
        // )[0];
        // const otherRole = relation.get(otherMemberIdRole);
        const otherFeature = layer
          .getSource()
          .getFeatures()
          .filter((myFeature) => myFeature.getId() === otherMemberId)[0];
        vizVectorSource.addFeature(otherFeature);
        // TODO change to this
        // const crosslink = toCrosslink(relation);
        // console.log(crosslink)
        // const sdFeature = layer.getSource().getFeatures().filter(myFeature => myFeature.getId() === relation.sdID)[0]
        // const latLonSDGeometry = toLatLon(sdFeature.getGeometry());
        // const latLonSDPart = lineSliceAlong()
        // const hdFeature = layer.getSource().getFeatures().filter(myFeature => myFeature.getId() === relation.hdID)[0]
      });
    }
  };
}
// function toCrosslink(crosslinkRelationFeature) {
//   const relationKeys = crosslinkRelationFeature
//     .getKeys()
//     .filter((key) => key.startsWith("relation-member-"));
//
//   const relationKeysMemberId = [
//     ...new Set(
//       relationKeys.map((key) =>
//         key.replace(/(relation-member-\d+)-.*$/, "$1"),
//       ),
//     ),
//   ];
//   const idAndRoles = relationKeysMemberId.map((id) => {
//     const role = crosslinkRelationFeature.get(id + "-role");
//     const tagKey = role.split("=", 2)[0];
//     const tagValue = role.split("=", 2)[1];
//     const decoder = new LRDecoder().withInterpolationSpecType(
//       InterpolationSpecType.TAG,
//     );
//     try {
//       const lrChain = decoder.decode(tagKey, tagValue);
//       return {
//         wayId: crosslinkRelationFeature.get(id + "-id"),
//         role: role,
//         lrChain: lrChain,
//         key: lrChain.chainName,
//         value: lrChain.elements[0].getValue(),
//         rangeStart: lrChain.elements[0].getStartDistance(),
//         rangeEnd: lrChain.elements[0].getEndDistance(),
//       };
//     } catch {
//       return {
//         wayId: crosslinkRelationFeature.get(id + "-id"),
//         role: role,
//         key: tagKey,
//         value: tagValue,
//         rangeStart: 0,
//         rangeEnd: UNKNOWN_END_DISTANCE,
//       };
//     }
//   });
//   const sdIdAndRole = idAndRoles.filter(
//     (idAndRole) => idAndRole.key === "road",
//   )[0];
//   const hdIdAndRole = idAndRoles.filter(
//     (idAndRole) => idAndRole.key === "lane",
//   )[0];
//   return {
//     sdID: sdIdAndRole.wayId,
//     hdID: hdIdAndRole.wayId,
//     sdRole: sdIdAndRole.value,
//     hdRole: hdIdAndRole.value,
//     sdRangeStart: sdIdAndRole.rangeStart,
//     sdRangeEnd: sdIdAndRole.rangeEnd,
//     hdRangeStart: hdIdAndRole.rangeStart,
//     hdRangeEnd: hdIdAndRole.rangeEnd,
//   };
// }

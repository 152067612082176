// const SRS = "EPSG:900913";
export const SUPPORTED_HIGHWAY_TYPES = [
  "living_street",
  "motorway",
  "motorway_link",
  "primary",
  "primary_link",
  "residential",
  "road",
  "secondary",
  "secondary_link",
  "service",
  "tertiary",
  "tertiary_link",
  "track",
  "trunk",
  "trunk_link",
  "unclassified",
];
